export default {
  methods: {
    perShippingCountTextFunc({ isUnitSame, shipping_unit, calculate_unit, per_shipping_count }) {
      if (isUnitSame) return '';
      if (!shipping_unit || !calculate_unit) return ''
      return `${per_shipping_count}${calculate_unit}/${shipping_unit}`;

      // if (this.isUnitSame) return null;
      // if(!this.shipping_unit || !this.calculate_unit) return null
      // return `每${this.shipping_unit}${this.product.per_shipping_count}${this.calculate_unit}`;
    },
    priceTextFunc({ unit, price, showPrice=true }) {
      if (!unit) {
        return showPrice ? `${price}元` : '元'
      }
      return showPrice ? `${price}元 / ${unit}` : `元 / ${unit}`;

      // if(!this.calculate_unit) return `${this.product.price}元`
      // return `${this.product.price}元 / ${this.calculate_unit}`;
    },
    discountRangeTextFunc({shipping_unit, min, max}) {
      if(!shipping_unit) return `${min}-${max}`
      return `${min}-${max}${shipping_unit}`;
    },
  },
}