<template>
  <v-container class="pa-0">
    <price-card2 v-model="data" :configs="localConfigs" :zebraStriping="zebraStriping"/>
  </v-container>
</template>

<script>
import cardMixins from "../mixins";
export default {
  mixins: [cardMixins],
    components: {
        priceCard2: () => import("@/modules/base/components/priceCard/priceCard2.vue"),
    },
    props: {
        zebraStriping: {
            type: Number,
        },
    },
  methods: {
    setPrice(configs) {
      const target = configs.find((config) => config.id === "price");
      if (!target) return;
      // if (this.isUnitSame) {
        target.label = `單價`;
        target.value = this.value.price;
        // return;
      // }
      // target.label = this.calculate_unit ? `單價(每${this.calculate_unit})` : '單價';
      // target.value = this.value.price;
    },
    calculateTotal(target, config) {
      const { per_shipping_count, shipping_count, price, shipping_unit } = config;
      const new_shipping_count = this.$helper.transformShippingCount({count: shipping_count, unit: shipping_unit})
      let total = null;
      // 出貨單位 = 計價單位
      if (this.isUnitSame) {
        total = new_shipping_count * Number(price);
      } else {
        // 出貨單位 != 計價單位
        total =
          Number(per_shipping_count) * new_shipping_count * Number(price);
      }
      target.value = isNaN(total) ? null : total;
      this.total = target.value
    },
  },
};
</script>