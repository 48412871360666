import unitText from "@/mixins/unitText.js";
export default {
  mixins: [unitText],
  components: {
    priceCard: () => import("./priceCard.vue"),
  },
  props: {
    index: {
      type: Number,
    },
    value: {
      type: Object,
      // default: () => ({
      //   shipping_unit: "籃", // 出貨單位
      //   calculate_unit: "包", // 計價單位
      //   per_shipping_count: 5, //  ex: 規格:每籃5包
      //   shipping_count: 12, // 出貨數量
      //   price: 10, // 單價
      //   order_count: 40, // 訂購數量 / 數量
      //   return_count: 0, // 退貨數量
      // }),
    },
    configs: {
      type: Array,
      // default: () => (
      //   [
      //     { label: "標題", colon: false },
      //     { id: "order_count", label: "訂購數量", col: 6 },
      //     { id: "unit", col: 6 }, // 需特別處理判斷 => 單位:斤 or 規格:每籃5包
      //     {
      //       id: "shipping_count",
      //       label: "出貨數量",
      //       type: "input",
      //       labelColor: "primary--text",
      //     },
      //     {
      //       id: "price",
      //       // type: "input",
      //       labelColor: "primary--text",
      //       valueSuffix: "/ 元",
      //     },
      //     {
      //       id: "total",
      //       label: "小計",
      //       type: "total",
      //       col: 12,
      //       valueSuffix: "元",
      //     },
      //   ]
      // ),
    },
  },
  data: () => ({
    localConfigs: [],
    data: null,
    total: 0,
    orderTotal: 0,
  }),
  computed: {
    // 計價單位 = 出貨單位 ?
    isUnitSame() {
      if (!this.shipping_unit) return false;
      if (!this.calculate_unit) return false;
      return this.shipping_unit === this.calculate_unit;
    },
    calculate_unit() {
      return this.value.calculate_unit ? this.value.calculate_unit : null;
    },
    shipping_unit() {
      return this.value.shipping_unit ? this.value.shipping_unit : null;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.value);
        this.setLocalConfigs();
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.data, this.value)) return;
        this.$emit("input", this.data);
      },
    },
  },
  methods: {
    setLocalConfigs() {
      const configs = this.$eagleLodash.cloneDeep(this.configs);
      if (!configs) return;
      this.setPerShippingCountText(configs);
      this.setOrderCount(configs);
      this.setUnit(configs);
      this.setShippingCount(configs);
      this.setPrice(configs);
      this.setOriginPrice(configs);
      this.setTotal(configs);
      this.setReturnCount(configs);
      this.localConfigs = configs;
    },

    setPerShippingCountText(configs){
      const target = configs.find((config) => config.id === "per_shipping_count_text");
      if (!target) return;
      target.value = this.value.per_shipping_count_text;
    },
    setOrderCount(configs) {
      const target = configs.find((config) => config.id === "order_count");
      if (!target) return;
      target.value = this.value.order_count;
      target.valueSuffix = this.shipping_unit;
    },
    setUnit(configs) {
      const target = configs.find((config) => config.id === "unit");
      if (!target) return;

      if (this.isUnitSame) {
        target.label = "單位";
        target.valueText = this.shipping_unit;
        return;
      }
      target.label = "規格";
      target.value = this.value.per_shipping_count;
      target.valueSuffix = this.calculate_unit;
      target.valueText = this.perShippingCountTextFunc({
        isUnitSame: this.isUnitSame,
        shipping_unit: this.shipping_unit,
        calculate_unit: this.calculate_unit,
        per_shipping_count: this.value.per_shipping_count,
      });
    },
    setShippingCount(configs) {
      const target = configs.find((config) => config.id === "shipping_count");
      if (!target) return;
      target.value = this.value.shipping_count;
      target.valueSuffix = this.shipping_unit;
      if(this.value.modify_count){
        target.label = '調整數量'
        target.value = this.value.modify_count;
      }
    },
    setTotal(configs) {
      const target = configs.find((config) => config.id === "total");
      if (!target) return;
      this.calculateTotal(target, this.value);
    },
    // TODO
    setPrice(configs) {},
    // TODO
    setOriginPrice(configs) {},
    // TODO
    calculateTotal(target, config) {},
    // TODO
    setReturnCount(configs) {},
  },
};
